
//本地引入
import { isExternal } from "@/utils/validate";

//公共库
import { Component, Prop, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "SidebarItemLink",
})

//组件函数
export default class extends Vue {
  @Prop({ required: true }) private to!: string;

  //是否外部链接
  private isExternal = isExternal;
}
