
//公共库
import { Component, Prop, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "Hamburger",
})

//界面函数
export default class extends Vue {
  //组件参数
  @Prop({ default: false }) private isActive!: boolean;

  //切换按钮
  private toggleClick(): void {
    //发送消息
    this.$emit("toggle-click");
  }
}
