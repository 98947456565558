//本地引入
import store from "@/store";
import defaultSettings from "@/settings";
import elementVariables from "@/styles/element-variables.scss";

//公共库
import { VuexModule, Module, Mutation, Action, getModule } from "vuex-module-decorators";

//设置状态结构
export interface ISettingsState {
  theme: string;
  fixedHeader: boolean;
  showSettings: boolean;
  showTagsView: boolean;
  showSidebarLogo: boolean;
  sidebarTextTheme: boolean;
}

/**
 * 设置模块
 */
@Module({ dynamic: true, store, name: "settings" })
class Settings extends VuexModule implements ISettingsState {
  //定义变量
  public theme = elementVariables.theme;
  public fixedHeader = defaultSettings.fixedHeader;
  public showSettings = defaultSettings.showSettings;
  public showTagsView = defaultSettings.showTagsView;
  public showSidebarLogo = defaultSettings.showSidebarLogo;
  public sidebarTextTheme = defaultSettings.sidebarTextTheme;

  //------------------ 本地函数 ------------------
  //改变设置
  @Mutation
  private CHANGE_SETTING(payload: { key: string; value: any }) {
    const { key, value } = payload;
    if (Object.prototype.hasOwnProperty.call(this, key)) {
      (this as any)[key] = value;
    }
  }

  //------------------ 外部调用 ------------------
  //改变设置
  @Action
  public changeSetting(payload: { key: string; value: any }) {
    this.CHANGE_SETTING(payload);
  }
}

//导出
export const SettingsModule = getModule(Settings);
