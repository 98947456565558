//本地引入
import store from "@/store";
import { getLocale } from "@/lang";
import { getSidebarStatus, setSidebarStatus, setLanguage } from "@/utils/cookies";

//公共库
import { VuexModule, Module, Mutation, Action, getModule } from "vuex-module-decorators";

//运行类型
export enum DeviceType {
  Mobile,
  Desktop,
}

//App状态结构
export interface IAppState {
  language: string; //语言
  device: DeviceType; //运行类型

  //侧边栏
  sidebar: {
    opened: boolean; //是否打开
    withoutAnimation: boolean; //是否动画
  };
}

/**
 * App模块
 */
@Module({ dynamic: true, store, name: "app" })
class App extends VuexModule implements IAppState {
  //侧边栏初始化
  public sidebar = {
    withoutAnimation: false,
    opened: getSidebarStatus() !== "closed",
  };

  //其他参数
  public language = getLocale(); //语言
  public device = DeviceType.Desktop; //类型

  //------------------ 本地函数 ------------------
  //设置语言
  @Mutation
  private SET_LANGUAGE(language: string) {
    this.language = language;
    setLanguage(this.language);
  }

  //切换设备
  @Mutation
  private TOGGLE_DEVICE(device: DeviceType) {
    this.device = device;
  }

  //关闭侧边栏
  @Mutation
  private CLOSE_SIDEBAR(withoutAnimation: boolean) {
    this.sidebar.opened = false;
    this.sidebar.withoutAnimation = withoutAnimation;
    setSidebarStatus("closed");
  }

  //切换侧边栏
  @Mutation
  private TOGGLE_SIDEBAR(withoutAnimation: boolean) {
    this.sidebar.opened = !this.sidebar.opened;
    this.sidebar.withoutAnimation = withoutAnimation;
    if (this.sidebar.opened) {
      setSidebarStatus("opened");
    } else {
      setSidebarStatus("closed");
    }
  }

  //------------------ 外部调用 ------------------
  //设置语言
  @Action
  public setLanguage(language: string) {
    this.SET_LANGUAGE(language);
  }

  //切换设备
  @Action
  public toggleDevice(device: DeviceType) {
    this.TOGGLE_DEVICE(device);
  }

  //关闭侧边栏
  @Action
  public closeSideBar(withoutAnimation: boolean) {
    this.CLOSE_SIDEBAR(withoutAnimation);
  }

  //切换侧边栏
  @Action
  public toggleSideBar(withoutAnimation: boolean) {
    this.TOGGLE_SIDEBAR(withoutAnimation);
  }
}

//导出
export const AppModule = getModule(App);
