//公共库
import Cookies from "js-cookie";

//缓存Key
let cookieKey = "admin_api_cfg";

//用户ID
const uidKey = `${cookieKey}uid_`;
export const setUid = (data: string) => Cookies.set(uidKey, data);
export const getUid = () => Cookies.get(uidKey);

//用户名
const nameKey = `${cookieKey}name_`;
export const setName = (data: string) => Cookies.set(nameKey, data);
export const getName = () => Cookies.get(nameKey);

//用户Token
const tokenKey = `${cookieKey}token_`;
export const getToken = () => Cookies.get(tokenKey);
export const setToken = (token: string) => Cookies.set(tokenKey, token);
export const removeToken = () => Cookies.remove(tokenKey);

//用户姓名
const realNameKey = `${cookieKey}realName_`;
export const setRealName = (data: string) => Cookies.set(realNameKey, data);
export const getRealName = () => Cookies.get(realNameKey);

//用户账号
const userNameKey = `${cookieKey}userName_`;
export const setUserName = (data: string) => Cookies.set(userNameKey, data);
export const getUserName = () => Cookies.get(userNameKey);

//语言设置
const languageKey = `${cookieKey}language_`;
export const getLanguage = () => Cookies.get(languageKey);
export const setLanguage = (language: string) => Cookies.set(languageKey, language);

//侧边栏状态
const sidebarStatusKey = `${cookieKey}sidebar_status_`;
export const getSidebarStatus = () => Cookies.get(sidebarStatusKey);
export const setSidebarStatus = (sidebarStatus: string) => Cookies.set(sidebarStatusKey, sidebarStatus);
