//本地引入
import i18n from "@/lang";
import GFunc from "./gFunc";
import DTCls from "./datetime";

//数据操作
export const ObjectEx = {
  /**
   * 合并数据
   * @param obj1 数据1
   * @param obj2 数据2
   */
  merge(obj1: any, obj2: any) {
    for (const key in obj2) {
      obj1[key] = obj2[key];
    }
    return obj1;
  },

  /**
   * 浅拷贝
   * @param obj 数据
   */
  copy(obj: any) {
    const newObj: any = {};
    for (const k in obj) {
      newObj[k] = obj[k];
    }
    return newObj;
  },
};

//归属国家
export const countryList: any[] = [
  { country: "", name: "全部国家" },
  { country: "COM", name: "COM" },
  { country: "MY", name: "MY" },
  { country: "ID", name: "ID" },
];

//归属国家
export const countryNoComList: any[] = [
  { country: "", name: "全部国家" },
  { country: "MY", name: "MY" },
  { country: "ID", name: "ID" },
];

//密码错误提示
export const getPwdErrMsg = (errCode: any) => {
  if (errCode === 0) {
    return i18n.t("utils.passwordErrorTips1") as string;
  } else {
    return i18n.t("utils.passwordErrorTips2") as string;
  }
};

//获取唯一随机数
export const uuid = (len: number, radix: number) => {
  var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  var uuid = [],
    i;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) {
      if (i == 7) {
        uuid[i] = "j";
      } else if (i == 15) {
        uuid[i] = "w";
      } else if (i == 23) {
        uuid[i] = "x";
      } else if (i == 28) {
        uuid[i] = "m";
      } else {
        uuid[i] = chars[0 | (Math.random() * radix)];
      }
    }
  } else {
    var r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join("");
};

//处理Class
export const hasClass = (ele: HTMLElement, className: string) => {
  return !!ele.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"));
};

//添加Class
export const addClass = (ele: HTMLElement, className: string) => {
  if (!hasClass(ele, className)) ele.className += " " + className;
};

//移除Class
export const removeClass = (ele: HTMLElement, className: string) => {
  if (hasClass(ele, className)) {
    const reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
};

//解析时间
export const parseTime = (time?: object | string | number | null, cFormat?: string): string | null => {
  //数据异常
  if (time === undefined || !time) return null;

  //定义变量
  let date: Date;

  //数据赋值
  const origTime = time;
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";

  //数据结构
  if (typeof time === "object") {
    date = time as Date;
  } else {
    //文本数据
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        time = parseInt(time);
      } else {
        time = time.replace(/-/gm, "/");
      }
    }

    //数字数据
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }

    //初始化时间
    date = new Date(time);

    //非法时间，转回原字串
    if (!DTCls.isValidDate(date)) {
      return GFunc.checkstring(origTime);
    }
  }

  //数据结构
  const formatObj: { [key: string]: number } = {
    a: date.getDay(),
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    y: date.getFullYear(),
    m: date.getMonth() + 1,
  };

  //时间处理
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    //数据赋值
    const value = formatObj[key];

    //星期
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }

    //返回数据
    return value.toString().padStart(2, "0");
  });

  //返回时间文本
  return timeStr;
};
