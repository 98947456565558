/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'system': {
    width: 800,
    height: 800,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 24H5v-2h2v-2H0V0h24v20h-7v2h2v2zM9 22h6v-2H9v2zm8-4h5V2H2v16h15zm-4-2h-2v-2.1c-.4-.1-.7-.2-1-.4L8.5 15l-1.4-1.4 1.5-1.5c-.2-.3-.3-.7-.4-1H6v-2h2.1c.1-.4.2-.7.4-1L7 6.6l1.4-1.4 1.5 1.5c.3-.2.7-.3 1-.4V4h2v2.1c.4.1.7.2 1 .4L15.4 5l1.4 1.4L15.4 8c.2.3.3.7.4 1H18v2h-2.1c-.1.4-.2.7-.4 1l1.5 1.5-1.4 1.4-1.6-1.5c-.3.2-.7.3-1 .4V16zm-1-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>'
  }
})
