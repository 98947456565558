/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'withdrawal': {
    width: 800,
    height: 800,
    viewBox: '0 -5.5 37 37',
    data: '<path pid="0" d="M22.957 22.325l.022-.026a4.331 4.331 0 002.184 1.03l.25.033V26a6.92 6.92 0 01-4.064-1.643l-.218-.194 1.826-1.838.022-.026zm5.882-.136l1.782 1.792a.372.372 0 00.076.06 6.933 6.933 0 01-4.111 1.934l-.3.025v-2.638a4.331 4.331 0 002.553-1.173zm-7.35-2.75l.024-.003c.073.742.329 1.428.723 2.015l.153.215-.048.04-1.814 1.825a7.003 7.003 0 01-1.611-3.783l-.026-.31h2.599l.024-.002zm8.697-.003l.024.002h2.599a7.002 7.002 0 01-1.363 3.746l-.172.223-.036-.046-1.826-1.836a4.375 4.375 0 00.774-2.089zm-4.337-3.943c1.926 0 3.487 1.57 3.487 3.507s-1.561 3.507-3.487 3.507c-1.925 0-3.486-1.57-3.486-3.507s1.56-3.507 3.486-3.507zM29.27 0c1.47 0 2.662 1.204 2.662 2.69l-.001.848h-4.834v-.282c0-.99-.795-1.793-1.774-1.793-.342 0-.676.1-.963.287l-6.13 4.006a1.805 1.805 0 000 3.012l4.227 2.763a8.004 8.004 0 00-4.688 7.297c0 .944.163 1.85.461 2.69H2.661C1.19 21.517 0 20.312 0 18.827V2.69C0 1.204 1.191 0 2.66 0h26.61zm-8.743 14.47l1.815 1.825.048.04a4.375 4.375 0 00-.84 1.953l-.036.276-.024-.002H18.89a7.005 7.005 0 011.636-4.092zm8.712 1.773l1.855-1.866a7.004 7.004 0 011.69 3.867l.026.318H30.21l-.024.002a4.375 4.375 0 00-.797-2.122l-.151-.199 1.855-1.866zM9.422 15.241H4.375a.828.828 0 00-.103 1.65l.103.006h5.047a.828.828 0 100-1.656zm7.588 0h-5.046a.828.828 0 00-.104 1.65l.104.006h5.046a.828.828 0 000-1.656zm8.403-3.24v2.628l.003.009a4.31 4.31 0 00-2.434 1.064l-.012-.014-.013-.013-1.827-1.838A6.926 6.926 0 0125.413 12zm.872 2.618V12a6.918 6.918 0 013.964 1.558l.23.197-1.854 1.865a4.328 4.328 0 00-2.079-.948l-.263-.034.002-.019V12zm1.006-3.636l4.64.001v2.844a7.944 7.944 0 00-4.64-2.845zm-1.97-7.727l.002 2.076H37v3.86H25.323l-.002 2.076-6.13-4.006 6.13-4.006zm-17.338.33c-.53 0-1.006.235-1.331.608a1.758 1.758 0 00-1.33-.608c-.98 0-1.774.803-1.774 1.793 0 .99.794 1.793 1.774 1.793.53 0 1.006-.235 1.33-.607.325.372.8.607 1.33.607.98 0 1.775-.802 1.775-1.793 0-.99-.795-1.793-1.774-1.793z" _fill="#1C1C1F" fill-rule="nonzero"/>'
  }
})
