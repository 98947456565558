/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'safety': {
    width: 800,
    height: 800,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M908.008 139.714L529.3 3.059A51.006 51.006 0 00511.902 0a51.316 51.316 0 00-17.535 3.09L117.016 139.745c-20.395 7.397-34.705 26.774-34.705 48.472v323.781c0 72.287 23.3 144.585 67.763 214.878 34.083 53.876 81.52 106.71 140.257 157.046 98.21 84.157 195.656 132.701 199.75 134.726a51.958 51.958 0 0022.966 5.352 51.76 51.76 0 0022.608-5.197c4.094-1.994 101.496-49.782 199.798-133.606 58.795-50.132 105.52-102.967 139.628-157.032 44.447-70.444 66.601-143.171 66.601-216.167V188.217c.004-21.732-13.23-41.133-33.674-48.503zM164.811 511.758v-284.99c0-10.848 6.648-20.539 16.847-24.237l320.76-116.693c2.86-1.035 6.143-1.798 9.58-1.798v427.962H861.63c.02 0 .044.464.044.76-.068 93.255-47.038 185.68-139.586 275.67-72.274 70.28-154.385 120.436-196.622 144.096-3.915 2.197-10.027 3.231-13.464 3.231V511.868l-347.191-.11z"/>'
  }
})
