//公共库
import Vue from "vue";

//本地引入
import settings from "@/settings";
import { isArray } from "@/utils/validate";
import { ErrorLogModule } from "@/store/modules/error-log";

//定义变量
const { errorLog: needErrorLog } = settings;

//监测
const checkNeed = () => {
  const env = process.env.NODE_ENV;
  if (isArray(needErrorLog) && env) {
    return needErrorLog.includes(env);
  }
  return false;
};

//处理
if (checkNeed()) {
  Vue.config.errorHandler = function (err, vm, info) {
    ErrorLogModule.addErrorLog({
      err,
      vm,
      info,
      url: window.location.href,
    });
  };
}
