
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { TagsViewModule } from "@/store/modules/tags-view";

//组件
@Component({
  name: "AppMain",
})

// 组件函数
export default class extends Vue {
  //主键
  get key() {
    return this.$route.path;
  }

  //缓存界面
  get cachedViews() {
    return TagsViewModule.cachedViews;
  }
}
