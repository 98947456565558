//本地引入
import i18n from "./lang";
import { CfgAnalyze } from "./utils/cfg-analyze";

//设置结构
interface ISettings {
  //基本信息
  title: string; //项目标题
  email: string; //项目邮箱
  errorLog: string[]; //错误日志
  serverPort: number; //服务端口
  introduction: string; //项目介绍

  //显示设置
  fixedHeader: boolean; //显示标头
  showSettings: boolean; //显示设置
  showTagsView: boolean; //显示控制标签试图
  showSidebarLogo: boolean; //显示控制侧边栏图标
  sidebarTextTheme: boolean; //显示侧边栏主题颜色
}

//获取项目标题
function getProjectTitle() {
  //标题
  const title: string = i18n.t("login.title") as string;

  //项目包名
  const packName: string = CfgAnalyze.getPackName();

  //返回项目标题
  return packName + title;
}

//设置数据
const settings: ISettings = {
  //基本信息
  serverPort: 49927, //开发端口
  introduction: "Admin", //项目介绍
  title: getProjectTitle(), //项目标题
  errorLog: ["production"], //错误日志
  email: "wukangyan2017@outlook.com", //项目邮箱

  //显示设置
  fixedHeader: false, //显示标头
  showTagsView: true, //显示设置
  showSettings: false, //显示控制标签试图
  showSidebarLogo: false, //显示控制侧边栏图标
  sidebarTextTheme: true, //显示侧边栏主题颜色
};

//导出设置数据
export default settings;
