/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'customer': {
    width: 800,
    height: 800,
    viewBox: '0 -0.5 17 17',
    data: '<g _fill="#434343" fill-rule="evenodd"><path pid="0" d="M9.005 12.094c-1.442 0-2.907-.856-3.762-1.989-4.102 0-4.226 5.86-4.226 5.86h15.975s.322-5.886-4.291-5.886c-.854 1.147-2.254 2.015-3.696 2.015zM12.441 5.069c0 1.686-1.539 4.91-3.439 4.91-1.897 0-3.437-3.225-3.437-4.91s1.539-3.053 3.437-3.053c1.9.001 3.439 1.369 3.439 3.053z" class="si-glyph-fill"/><path pid="1" d="M13.975 3.614c0-.316-.44-.571-.986-.573V2.39c0-.097.037-2.368-3.975-2.368-4.01 0-3.973 2.271-3.973 2.368v.674c-.01 0-.018-.003-.027-.003-.543 0-.98.251-.98.562v2.754c0 .309.438.561.98.561s.982-.252.982-.561V3.623c0-.043-.027-.082-.043-.122V2.75c0-.069-.288-1.783 3.061-1.783 3.35 0 3.002 1.714 3.002 1.783v.791c-.006.025-.025.047-.025.073v2.817c0 .317.443.574.992.574.012 0 .021-.004.033-.004V8.03h-.984V9h1.953l-.01-5.386z" class="si-glyph-fill"/></g>'
  }
})
