export default {
  //实例
  utils: {
    usernameErrorTips: "Please enter user name",
    passwordErrorTips1: "Password cannot be less than 6 characters",
    passwordErrorTips2: "Password must contain 2 types of numbers, letters, and special characters",
  },

  //登录
  login: {
    logIn: "Login",
    username: "Username",
    password: "Password",
    title: "Backend Management System",
    loginFailure: "Incorrect username or password",
  },

  //导航
  navbar: {
    theme: "Theme",
    logOut: "Log Out",
    profile: "Profile",
    dashboard: "Dashboard",
  },

  //个人中心
  profile: {
    save: "Save",
    cancel: "Cancel",
    oldPassword: "Old Password",
    newPassword: "New Password",
    changePassword: "Change Password",
    confirmPassword: "Confirm Password",
    personalInformation: "Personal Information",
    pleaseInputOldPassword: "Please Input Old Password",
    pleaseInputNewPassword: "Please Input New Password",
    pleaseConfirmNewPassword: "Please Confirm New Password",
    changeSuccess: "Password changed successfully, please login again",
    newPasswordError: "The passwords entered twice are inconsistent",
    changeFailure: "Failed to change password",
  },

  //标签
  tagsView: {
    close: "Close",
    refresh: "Refresh",
    closeAll: "Close All",
    closeOthers: "Close Others",
  },

  //设置
  settings: {
    theme: "Theme Color",
    title: "Page style setting",
    fixedHeader: "Fixed Header",
    showTagsView: "Open Tags-View",
    showSidebarLogo: "Sidebar Logo",
    sidebarTextTheme: "Sidebar Text Theme",
  },

  //错误页面
  errorpage: {
    back: "Back",
    tips: "Tips",
    backDashboard: "Back Dashboard",
    backDashboardTips: "Or you can go",
    notExistTips: "Page does not exist",
    permissionTips: "You don't have permission to go to this page",
    contactAdmin: "If you are dissatisfied, please contact the administrator",
    notExistInfo: "Please check whether the URL you entered is correct, or click the button below to return to the home page.",
  },

  //权限
  permission: {
    delete: "Delete",
    cancel: "Cancel",
    confirm: "Confirm",
    roles: "Your roles",
    editPermission: "Edit",
    createRole: "New Role",
    switchRoles: "Switch roles",
  },

  //组件
  components: {
    changeLanguageTips: "Switch Language Success",
    screenfullErrorTips: "you browser can not work",
  },
};
