/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'blogger1': {
    width: 800,
    height: 800,
    viewBox: '-2 -2 24 24',
    data: '<path pid="0" d="M13.593 19.96c3.48 0 6.307-2.836 6.327-6.297l.039-5.095-.059-.278-.167-.348-.283-.22c-.367-.287-2.228.02-2.729-.435-.355-.324-.41-.91-.518-1.706-.2-1.54-.326-1.62-.568-2.142C14.76 1.585 12.382.193 10.75 0H6.325C2.845 0 0 2.839 0 6.307v7.356c0 3.461 2.845 6.296 6.325 6.296h7.268zM6.406 5.151h3.507c.67 0 1.212.544 1.212 1.205 0 .657-.542 1.206-1.212 1.206H6.406c-.67 0-1.21-.549-1.21-1.206 0-.661.54-1.205 1.21-1.205zm-1.21 8.418c0-.66.54-1.2 1.21-1.2h7.127c.665 0 1.205.54 1.205 1.2 0 .652-.54 1.2-1.205 1.2H6.406a1.21 1.21 0 01-1.21-1.2z"/>'
  }
})
