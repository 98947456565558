/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'activity': {
    width: 800,
    height: 800,
    viewBox: '-1 0 19 19',
    data: '<path pid="0" d="M16.417 9.583A7.917 7.917 0 118.5 1.666a7.917 7.917 0 017.917 7.917zm-4.339-3.332a.794.794 0 00-.791-.792h-.268v.767A1.11 1.11 0 019.91 7.334H6.99a1.11 1.11 0 01-1.11-1.108v-.767h-.267a.794.794 0 00-.792.792v7.403a.794.794 0 00.792.792h5.673a.794.794 0 00.791-.792zm-1.077 2.522H5.787v.792h5.214zm0 1.713H5.787v.792h5.214zm0 1.713H5.787v.791h5.214zM6.673 6.226a.318.318 0 00.317.317h2.92a.318.318 0 00.317-.317v-.914a.318.318 0 00-.316-.317h-.557v-.843a.318.318 0 00-.317-.316H7.864a.318.318 0 00-.317.316v.843H6.99a.318.318 0 00-.317.317zm1.384-1.581a.396.396 0 11.396.396.396.396 0 01-.396-.396z"/>'
  }
})
