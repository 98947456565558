
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { ErrorLogModule } from "@/store/modules/error-log";

//组件
@Component({
  name: "ErrorLog",
})

//界面函数
export default class extends Vue {
  //定义变量
  private dialogTableVisible = false;

  //错误日志
  get errorLogs() {
    return ErrorLogModule.logs;
  }

  //清除所有
  private clearAll(): void {
    //隐藏界面
    this.dialogTableVisible = false;

    //清空数据
    ErrorLogModule.clearErrorLog();
  }
}
