/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'tag': {
    width: 800,
    height: 800,
    viewBox: '0 0 33 33',
    data: '<path pid="0" d="M24.816 13.835a3.991 3.991 0 01-5.648 0 3.998 3.998 0 010-5.652 3.99 3.99 0 015.648 0 3.998 3.998 0 010 5.652zM30.097.993H16.683c-1.059 0-1.252.462-2.261 1.518l-9.985 9.955 16.115 16.115 10.011-9.979c.849-.848 1.451-1.27 1.451-2.326V2.903c0-1.054-.859-1.91-1.917-1.91zM20.58 9.596a1.997 1.997 0 102.824 0 1.995 1.995 0 00-2.824 0zm-19.363 6.08a4.127 4.127 0 000 5.85l10.27 10.241a4.16 4.16 0 005.869 0l1.779-1.774L3.02 13.878l-1.803 1.798z" _fill="#000" fill-rule="evenodd"/>'
  }
})
