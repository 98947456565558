
//公共库
import { Component, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "ScrollPane",
})

//组件函数
export default class extends Vue {
  //定义变量
  private tagSpacing: number = 4;

  //初始化
  mounted() {
    this.scrollWrapper.addEventListener("scroll", this.emitScroll, true);
  }

  //销毁前
  beforeDestroy() {
    this.scrollWrapper.removeEventListener("scroll", this.emitScroll);
  }

  //滚动事件
  private emitScroll(): void {
    this.$emit("scroll");
  }

  //滚动组件
  get scrollWrapper(): HTMLElement {
    return (this.$refs.scrollContainer as Vue).$refs.wrap as HTMLElement;
  }

  //处理滚动
  private handleScroll(e: WheelEvent): void {
    const eventDelta = (e as any).wheelDelta || -e.deltaY * 40;
    const scrollWrapper = this.scrollWrapper;
    scrollWrapper.scrollLeft = scrollWrapper.scrollLeft + eventDelta / 4;
  }

  //滚动到目标
  public moveToTarget(currentTag: HTMLElement) {
    //数据赋值
    const container = (this.$refs.scrollContainer as Vue).$el as HTMLElement;
    const tagList = this.$parent?.$refs.tag as any[];
    const containerWidth = container.offsetWidth;
    const scrollWrapper = this.scrollWrapper;

    //第一个和最后一个目标
    let lastTag = null;
    let firstTag = null;
    if (tagList.length > 0) {
      firstTag = tagList[0];
      lastTag = tagList[tagList.length - 1];
    }

    //第一个目标
    if (firstTag === currentTag) {
      scrollWrapper.scrollLeft = 0;
    }
    //最后一个目标
    else if (lastTag === currentTag) {
      scrollWrapper.scrollLeft = scrollWrapper.scrollWidth - containerWidth;
    } else {
      //当前和下一个目标赋值
      const currentIndex = tagList.findIndex((item) => item === currentTag);
      const prevTag = tagList[currentIndex - 1];
      const nextTag = tagList[currentIndex + 1];

      //关闭后一个目标赋值
      const afterNextTagOffsetLeft = nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + this.tagSpacing;

      //关闭前一个目标
      const beforePrevTagOffsetLeft = prevTag.$el.offsetLeft - this.tagSpacing;

      //判断显示
      if (afterNextTagOffsetLeft > scrollWrapper.scrollLeft + containerWidth) {
        scrollWrapper.scrollLeft = afterNextTagOffsetLeft - containerWidth;
      } else if (beforePrevTagOffsetLeft < scrollWrapper.scrollLeft) {
        scrollWrapper.scrollLeft = beforePrevTagOffsetLeft;
      }
    }
  }
}
