//本地引入
import store from "@/store";

//公共库
import { VuexModule, Module, Mutation, Action, getModule } from "vuex-module-decorators";

//错误日志结构
export interface IErrorLog {
  vm: any;
  err: Error;
  url: string;
  info: string;
}

//错误日志状态
export interface IErrorLogState {
  logs: IErrorLog[];
}

/**
 * 错误日志模块
 */
@Module({ dynamic: true, store, name: "errorLog" })
class ErrorLog extends VuexModule implements IErrorLogState {
  //定义变量
  public logs: IErrorLog[] = [];

  //------------------ 本地函数 ------------------
  //清除错误日志
  @Mutation
  private CLEAR_ERROR_LOG() {
    this.logs.splice(0);
  }

  //添加错误日志
  @Mutation
  private ADD_ERROR_LOG(log: IErrorLog) {
    this.logs.push(log);
  }

  //------------------ 外部调用 ------------------
  //清除错误日志
  @Action
  public clearErrorLog() {
    this.CLEAR_ERROR_LOG();
  }

  //添加错误日志
  @Action
  public addErrorLog(log: IErrorLog) {
    this.ADD_ERROR_LOG(log);
  }
}

//导出
export const ErrorLogModule = getModule(ErrorLog);
