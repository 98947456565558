/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'medal': {
    width: 800,
    height: 800,
    viewBox: '0 0 64 64',
    data: '<circle pid="0" cx="33.52" cy="39.9" r="20.42" stroke-linecap="round"/><path pid="1" d="M33.65 27.91l3.64 7.38a.12.12 0 00.1.07l8.15 1.19a.13.13 0 01.07.23l-5.89 5.74a.11.11 0 000 .12l1.39 8.12a.14.14 0 01-.2.14l-7.28-3.83a.15.15 0 00-.13 0l-7.32 3.83a.14.14 0 01-.2-.14l1.39-8.12a.14.14 0 000-.12l-5.89-5.74a.13.13 0 01.07-.23l8.15-1.19a.12.12 0 00.1-.07l3.64-7.38a.14.14 0 01.21 0z" stroke-linecap="round"/><path pid="2" d="M21.48 23.75L9.89 3.67h10.08l9.07 15.71-9.07-15.71"/><path pid="3" stroke-linecap="round" d="M44.82 22.89l11.1-19.22H45.79l-9.02 15.71"/>'
  }
})
