
//本地引入
import { AppModule } from "@/store/modules/app";
import { UserModule } from "@/store/modules/user";
import ErrorLog from "@/comp/ErrorLog/index.vue";
import Hamburger from "@/comp/Hamburger/index.vue";
import Breadcrumb from "@/comp/Breadcrumb/index.vue";
import LangSelect from "@/comp/LangSelect/index.vue";
import Screenfull from "@/comp/Screenfull/index.vue";

//公共库
import { Component, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "Navbar",
  components: {
    ErrorLog,
    Hamburger,
    Breadcrumb,
    LangSelect,
    Screenfull,
  },
})

//组件函数
export default class extends Vue {
  //侧边栏
  get sidebar(): any {
    return AppModule.sidebar;
  }

  //运行设备
  get device(): string {
    return AppModule.device.toString();
  }

  //头像
  get avatar(): string {
    return UserModule.avatar;
  }

  //登出账号
  private async logout() {
    //登出
    await UserModule.logOut();

    //切换界面
    this.$router.push(`/login?redirect=${this.$route.fullPath}`).catch((err) => {
      console.warn(err);
    });
  }

  //切换侧边栏
  private toggleSideBar(): void {
    AppModule.toggleSideBar(false);
  }
}
