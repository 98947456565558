
//本地引入
import screenfull from "screenfull";

//公共库
import { Component, Vue } from "vue-property-decorator";

//定义变量
const sf = screenfull;

//组件
@Component({
  name: "Screenfull",
})

//界面函数
export default class extends Vue {
  //定义变量
  private isFullscreen = false;

  //初始化
  mounted() {
    //注册事件
    if (sf.isEnabled) sf.on("change", this.change);
  }

  //销毁前调用
  beforeDestory() {
    //注销事件
    if (sf.isEnabled) sf.off("change", this.change);
  }

  //按钮事件
  private click() {
    //组件是否可用
    if (!sf.isEnabled) {
      //显示提示
      this.$message({
        message: this.$t("components.screenfullErrorTips").toString(),
        type: "warning",
      });
      return false;
    }

    //切换
    sf.toggle();
  }

  //切换事件
  private change(): void {
    if (sf.isEnabled) this.isFullscreen = sf.isFullscreen;
  }
}
