
//公共库
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

//本地引入
import ResizeMixin from "./mixin/resize";
import { SettingsModule } from "@/store/modules/settings";
import { DeviceType, AppModule } from "@/store/modules/app";
import { AppMain, Navbar, Sidebar, TagsView } from "./components";

//组件
@Component({
  name: "Layout",
  components: {
    Navbar,
    AppMain,
    Sidebar,
    TagsView,
  },
})

//组件函数
export default class extends mixins(ResizeMixin) {
  //参数
  get classObj(): any {
    return {
      openSidebar: this.sidebar.opened,
      hideSidebar: !this.sidebar.opened,
      mobile: this.device === DeviceType.Mobile,
      withoutAnimation: this.sidebar.withoutAnimation,
    };
  }

  //显示头部
  get fixedHeader(): boolean {
    return SettingsModule.fixedHeader;
  }

  //显示设置
  get showSettings(): boolean {
    return SettingsModule.showSettings;
  }

  //显示标签
  get showTagsView(): boolean {
    return SettingsModule.showTagsView;
  }

  //处理关闭侧边栏
  private handleClickOutside(): void {
    AppModule.closeSideBar(false);
  }
}
