//公共库
import Vue from "vue";
import Vuex from "vuex";

//本地引入
import { IAppState } from "./modules/app";
import { IUserState } from "./modules/user";
import { ISettingsState } from "./modules/settings";
import { ITagsViewState } from "./modules/tags-view";
import { IErrorLogState } from "./modules/error-log";
import { IPermissionState } from "./modules/permission";

//使用
Vue.use(Vuex);

//基础结构
export interface IRootState {
  app: IAppState;
  user: IUserState;
  tagsView: ITagsViewState;
  errorLog: IErrorLogState;
  settings: ISettingsState;
  permission: IPermissionState;
}

//导出
export default new Vuex.Store<IRootState>({});
