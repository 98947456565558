//是否数组
export const isArray = (arg: any) => {
  //判断是否数组
  if (typeof Array.isArray === "undefined") {
    return Object.prototype.toString.call(arg) === "[object Array]";
  }

  //返回结果
  return Array.isArray(arg);
};

//是否有效网址
export const isValidURL = (url: string) => {
  const reg =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
};

//是否有效密码
export const isValidPassword = (pwd: string) => {
  pwd = pwd.trim();
  if (pwd.length >= 6 && pwd.length <= 16) {
    const regNum = /[0-9]/;
    const regChar = /[a-z]/i;
    const regSpec = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
    const regCn = /[\u0391-\uFFE5]+/g;
    const hCn = regCn.test(pwd);
    if (hCn) {
      return -1;
    }
    const hNum = regNum.test(pwd);
    const hChar = regChar.test(pwd);
    const hSpec = regSpec.test(pwd);
    if ((hNum && hChar) || (hNum && hSpec) || (hChar && hSpec)) {
      return 1;
    }
    return -1;
  }
  return 0;
};

//是否外部连接
export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path);

//是否有效用户名
export const isValidUsername = (str: string) => ["admin", "editor"].indexOf(str.trim()) >= 0;
