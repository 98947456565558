//静态变量
const CFG_VS: string = "buildVersion";
const CFG_NAME: string = "admin_api_cfg";

//配置
const CfgAnalyze = {
  //获取配置
  getCfg(key: string): any {
    return (window as any)[CFG_NAME] && (window as any)[CFG_NAME][key];
  },

  //获取版本
  getVersionCfg(key: string) {
    return (window as any)[CFG_VS] && (window as any)[CFG_VS][key];
  },

  //------------------------ 外部调用 ------------------------
  //获取api地址
  getBaseApi(): string {
    return this.getCfg("BASE_API");
  },

  //包类型
  getPackType(): number {
    return this.getCfg("PACK_TYPE") || 0;
  },

  //包名称
  getPackName(): string {
    return this.getCfg("PACK_NAME") || "";
  },

  //打包版本号
  getVersionCode(): number {
    return this.getVersionCfg("buildVersion") || 0;
  },
};

//导出
export { CfgAnalyze };
