
//公共库
import { Component, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "App",
})

//组件函数
export default class extends Vue {}
