import { VuexModule, Module, Action, Mutation, getModule } from "vuex-module-decorators";

//本地引入
import store from "@/store";
import settings from "@/settings";
import { TagsViewModule } from "./tags-view";
import router, { resetRouter } from "@/router";
import { PermissionModule } from "./permission";
import { authLogin, getUserInfo, getPermissionMenu } from "@/api/request/base";
import { getToken, setToken, removeToken, setUid, getUserName, setUserName, getRealName, setRealName } from "@/utils/cookies";

//用户状态结构
export interface IUserState {
  //基本信息
  uid: number; //ID
  name: string; //名称
  email: string; //邮箱
  token: string; //Token
  avatar: string; //头像
  username: string; //用户名
  introduction: string; //备注
  roles: string[]; //角色

  //路由配置
  routeCfg: any; // 路由权限列表
  roleRouteCfg: any; //角色路由权限列表
}

/**
 * 用户模块
 */
@Module({ dynamic: true, store, name: "user" })
class User extends VuexModule implements IUserState {
  //路由配置
  public routeCfg: any = [];
  public roleRouteCfg: any = [];
  public isOverTime = false; //是否超时

  //基本信息
  public uid: number = 0; //ID
  public email: string = ""; //邮箱
  public avatar: string = ""; //头像
  public roles: string[] = []; //角色
  public introduction: string = ""; //备注
  public token: string = getToken() || ""; //Token
  public name: string = getRealName() || ""; //名称
  public username: string = getUserName() || ""; //用户名

  //------------------ 本地函数 ------------------
  //设置ID
  @Mutation
  private SET_UID(uid: number) {
    this.uid = uid;
  }

  //设置名称
  @Mutation
  private SET_NAME(name: string) {
    this.name = name;
  }

  //设置邮箱
  @Mutation
  private SET_EMAIL(email: string) {
    this.email = email;
  }

  //设置Token
  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  //设置路由
  @Mutation
  private SET_ROUTE_CFG(cfg: any) {
    this.routeCfg = cfg;
  }

  //设置头像
  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar;
  }

  //设置角色
  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles;
  }

  //设置角色路由
  @Mutation
  private SET_ROLE_ROUTE_CFG(cfg: any) {
    if (!cfg) {
      cfg = [];
    } else if (cfg === "*") {
      cfg = ["*"];
    } else {
      cfg = JSON.parse(cfg);
    }
    this.roleRouteCfg = cfg;
  }

  //设置用户名
  @Mutation
  private SET_USERNAME(username: string) {
    this.username = username;
  }

  //设置是否超时
  @Mutation
  private SET_IS_OVER_TIME(status: boolean) {
    this.isOverTime = status;
  }

  //设置备注
  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction;
  }

  //------------------ 外部调用 ------------------
  //重置Token
  @Action
  public resetToken() {
    removeToken();
    this.SET_TOKEN("");
    this.SET_ROLES([]);
  }

  @Action
  public async logOut() {
    //异常
    if (this.token === "") throw Error("LogOut: Token不存在!");

    //清除缓存
    removeToken();
    resetRouter();

    //重置界面
    TagsViewModule.delAllViews();

    //重置数据
    this.SET_TOKEN("");
    this.SET_ROLES([]);
    this.SET_ROUTE_CFG([]);
    this.SET_ROLE_ROUTE_CFG("[]");
  }

  //设置超时
  @Action
  public setIsOverTime() {
    this.SET_IS_OVER_TIME(true);
  }

  //获取管理员信息
  @Action
  public async getUserInfo() {
    //异常判断
    if (this.token === "") throw Error("GetUserInfo: Token不存在!");

    //数据初始化
    const { avatar, email, introduction } = {
      email: settings.email,
      introduction: settings.introduction,
      avatar: "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
    };

    //设置数据
    this.SET_EMAIL(email);
    this.SET_AVATAR(avatar);
    this.SET_INTRODUCTION(introduction);

    //获取用户信息
    const { data } = await getUserInfo({});

    //异常判断
    if (!data) throw Error("Verification failed, please Login again.");

    //设置角色
    if (data.type !== 1) {
      this.SET_ROLES(["editor"]);
    } else {
      this.SET_ROLES(["admin"]);
    }

    //设置玩家路由
    this.SET_ROLE_ROUTE_CFG(data.perm_config);

    //设置用户名
    setUserName(data.username);
    this.SET_USERNAME(data.username);
  }

  //获取权限列表
  @Action
  public async getPermissionMenu() {
    //获取权限
    const { data } = await getPermissionMenu({});

    //设置权限
    this.SET_ROUTE_CFG(data.result);
  }

  //改变角色
  @Action
  public async changeRoles(role: string) {
    //数据赋值
    const token = role + "-token";

    //设置Token
    setToken(token);
    this.SET_TOKEN(token);

    //获取用户信息
    await this.getUserInfo();

    //重置路由
    resetRouter();

    //生成路由
    PermissionModule.generateRoutes();

    //添加动态路由
    PermissionModule.dynamicRoutes.forEach((route) => {
      router.addRoute(route);
    });

    //重置界面
    TagsViewModule.delAllViews();
  }

  //账号登录
  @Action
  public async authLogin(userInfo: { username: string; password: string; code: string }) {
    //数据初始化
    let { username, password, code } = userInfo;

    //数据赋值
    code = code.trim();
    username = username.trim();
    password = password.trim();

    //账号登录
    const { data } = await authLogin({ username, password, code });

    //保存缓存
    setUid(data.uid);
    setToken(data.token);
    setRealName(data.realmname);

    //调用本地函数
    this.SET_UID(data.uid);
    this.SET_TOKEN(data.token);
    this.SET_NAME(data.realmname);
    this.SET_IS_OVER_TIME(false);
  }
}

//导出
export const UserModule = getModule(User);
