/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'blogger': {
    width: 800,
    height: 800,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.69 8.793a.84.84 0 00-.158.783L8.53 9.57c.05.279.261.498.531.559l.005.001a11.713 11.713 0 001.683.053l-.022.001h1.34c.141-.023.267-.061.386-.111l-.011.004a.868.868 0 00.371-.712l-.001-.042v.002l.001-.035a.725.725 0 00-.425-.66l-.005-.002a.652.652 0 00-.36-.107h-1.351q-1.232 0-1.5.026a.782.782 0 00-.48.24zm3.323 5.032q-2.144 0-2.599.054a1.246 1.246 0 00-.621.19l.005-.003a.819.819 0 00-.238.342l-.002.006a.807.807 0 00-.053.404v-.004c.024.143.08.27.162.377l-.002-.002a.738.738 0 00.343.266l.005.002a23.933 23.933 0 003.043.078l-.043.001 2.572-.054.48-.054a.822.822 0 00.429-.637v-.004a.731.731 0 00-.267-.696l-.001-.001a1.136 1.136 0 00-.616-.187h-.001q-.4-.026-2.599-.026zm11.949 7.021q.054-.483.054-9.163V4.721q-.054-1.447-.107-1.929a1.655 1.655 0 00-.219-.597l.004.007-.054-.214A3.6 3.6 0 0021.09.062l-.022-.003q-.32-.054-9.136-.054T2.85.059a3.9 3.9 0 00-1.743.962l.001-.001a3.594 3.594 0 00-.987 1.691l-.005.025A5.693 5.693 0 00.01 4.25l-.001-.016v15.595c.011.542.05 1.062.115 1.573l-.008-.073c.108.437.283.82.517 1.161l-.008-.012c.247.351.55.644.898.873l.013.008c.345.235.748.419 1.179.53l.027.006c.456.057.995.095 1.541.104h15.446c.542-.011 1.062-.05 1.573-.115l-.073.008a3.594 3.594 0 001.873-1.09l.003-.003a3.298 3.298 0 00.856-1.938l.001-.013zm-4.554-9.968q.054.32.026 2.492a16.62 16.62 0 01-.143 2.629l.009-.084a4.512 4.512 0 01-1.179 2.09 4.45 4.45 0 01-2.656 1.391l-.023.003q-.48.054-3.644.026t-3.537-.08a4.505 4.505 0 01-2.12-1.076l.004.003a4.449 4.449 0 01-1.357-2.007l-.009-.031a4.374 4.374 0 01-.187-1.23v-.003q-.026-.75-.026-3.322.054-3.108.107-3.43a5.25 5.25 0 011.051-2.042l-.007.008a4.444 4.444 0 011.818-1.276l.031-.01a3.62 3.62 0 011.219-.319l.014-.001q.64-.054 2.357-.054 1.822.054 2.09.107c.859.29 1.594.749 2.198 1.341l-.001-.001a4.32 4.32 0 011.34 3.05v.004a1.69 1.69 0 00.083.628l-.003-.012a.724.724 0 00.291.318l.003.002a.734.734 0 00.373.134h.002l.64.054c.287.007.562.046.826.113l-.025-.006a.84.84 0 01.434.585l.001.005z"/>'
  }
})
