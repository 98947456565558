
//公共库
import path from "path";
import { RouteConfig } from "vue-router";
import { Component, Vue, Watch } from "vue-property-decorator";

//本地引入
import ScrollPane from "./ScrollPane.vue";
import { PermissionModule } from "@/store/modules/permission";
import { TagsViewModule, ITagView } from "@/store/modules/tags-view";

//组件
@Component({
  name: "TagsView",
  components: {
    ScrollPane,
  },
})

//组件函数
export default class extends Vue {
  //定义变量
  private top: number = 0;
  private left: number = 0;
  private visible: boolean = false;
  private affixTags: ITagView[] = [];
  private selectedTag: ITagView = {};

  //初始化
  mounted() {
    //初始化标签
    this.initTags();

    //添加标签
    this.addTags();
  }

  //------------------------------- 数据 -------------------------------
  //路由数据
  get routes(): RouteConfig[] {
    return PermissionModule.routes;
  }

  //访问界面数据
  public get visitedViews(): ITagView[] {
    return TagsViewModule.visitedViews;
  }

  //------------------------------- 函数 -------------------------------
  //初始化标签
  private initTags(): void {
    //活驴标签
    this.affixTags = this.filterAffixTags(this.routes);

    //标签数据赋值
    for (const tag of this.affixTags) {
      //必须要名字
      if (tag.name) TagsViewModule.addVisitedView(tag);
    }
  }

  //添加标签
  private addTags(): void {
    //数据赋值
    const { name } = this.$route;

    //添加标签
    if (name) TagsViewModule.addView(this.$route);
  }

  //关闭菜单
  private closeMenu(): void {
    //隐藏界面
    this.visible = false;
  }

  //处理滚动
  private handleScroll(): void {
    //关闭菜单
    this.closeMenu();
  }

  //监听路由
  @Watch("$route")
  private onRouteChange(): void {
    //添加标签
    this.addTags();

    //移到目标表标签
    this.moveToCurrentTag();
  }

  //关闭其他标签
  private closeOthersTags(): void {
    //数据赋值
    if (this.selectedTag.fullPath !== this.$route.path && this.selectedTag.fullPath !== undefined) {
      this.$router.push(this.selectedTag.fullPath).catch((err) => {
        console.warn(err);
      });
    }

    //删除其他界面
    TagsViewModule.delOthersViews(this.selectedTag);

    //移动到当前标签
    this.moveToCurrentTag();
  }

  //移动到当前标签
  private moveToCurrentTag(): void {
    //数据赋值
    const tags = this.$refs.tag as any[];

    //处理标签
    this.$nextTick(() => {
      for (const tag of tags) {
        if ((tag.to as ITagView).path === this.$route.path) {
          (this.$refs.scrollPane as ScrollPane).moveToTarget(tag as any);
          if ((tag.to as ITagView).fullPath !== this.$route.fullPath) {
            TagsViewModule.updateVisitedView(this.$route);
          }
          break;
        }
      }
    });
  }

  //是否可关闭
  private isAffix(tag: ITagView): boolean {
    return tag.meta && tag.meta.affix;
  }

  //是否激活
  private isActive(route: ITagView): boolean {
    return route.path === this.$route.path;
  }

  //关闭所有标签
  private closeAllTags(view: ITagView): void {
    //删除界面
    TagsViewModule.delAllViews();

    //当前页面不可关闭
    if (this.affixTags.some((tag) => tag.path === this.$route.path)) {
      return;
    }

    //显示最后一个界面
    this.toLastView(TagsViewModule.visitedViews, view);
  }

  //监听显示状态
  @Watch("visible")
  private onVisibleChange(value: boolean): void {
    //注册事件
    if (value) {
      document.body.addEventListener("click", this.closeMenu);
    }
    //注销事件
    else {
      document.body.removeEventListener("click", this.closeMenu);
    }
  }

  //关闭选择标签
  private closeSelectedTag(view: ITagView): void {
    //删除界面
    TagsViewModule.delView(view);

    //激活状态切换界面
    if (this.isActive(view)) {
      this.toLastView(TagsViewModule.visitedViews, view);
    }
  }

  //刷新选择标签
  private refreshSelectedTag(view: ITagView): void {
    //删除界面
    TagsViewModule.delCachedView(view);

    //重新加载界面
    const { fullPath } = view;
    this.$nextTick(() => {
      this.$router
        .replace({
          path: "/redirect" + fullPath,
        })
        .catch((err) => {
          console.warn(err);
        });
    });
  }

  //打开菜单
  private openMenu(tag: ITagView, e: MouseEvent): void {
    //数据赋值
    const menuMinWidth: number = 105;
    const offsetLeft: number = this.$el.getBoundingClientRect().left;
    const offsetWidth: number = (this.$el as HTMLElement).offsetWidth;
    const maxLeft: number = offsetWidth - menuMinWidth;
    const left: number = e.clientX - offsetLeft + 15;

    //显示位置
    if (left > maxLeft) {
      this.left = maxLeft;
    } else {
      this.left = left;
    }
    this.top = e.clientY;

    //数据赋值
    this.selectedTag = tag;

    //显示界面
    this.visible = true;
  }

  //显示最后一个界面
  private toLastView(visitedViews: ITagView[], view: ITagView): void {
    //数据赋值
    const latestView = visitedViews.slice(-1)[0];

    //正常显示
    if (latestView !== undefined && latestView.fullPath !== undefined) {
      this.$router.push(latestView.fullPath).catch((err) => {
        console.warn(err);
      });
    }
    //显示首页
    else {
      //显示重定向界面
      if (view.name === "Dashboard") {
        this.$router.replace({ path: "/redirect" + view.fullPath }).catch((err) => {
          console.warn(err);
        });
      }
      //显示主页
      else {
        this.$router.push("/").catch((err) => {
          console.warn(err);
        });
      }
    }
  }

  //过滤标签
  private filterAffixTags(routes: RouteConfig[], basePath = "/"): ITagView[] {
    //定义变量
    let tags: ITagView[] = [];

    //数据赋值
    routes.forEach((route) => {
      if (route.meta && route.meta.affix) {
        const tagPath = path.resolve(basePath, route.path);
        tags.push({
          fullPath: tagPath,
          path: tagPath,
          name: route.name,
          meta: { ...route.meta },
        });
      }
      if (route.children) {
        const childTags = this.filterAffixTags(route.children, route.path);
        if (childTags.length >= 1) {
          tags = [...tags, ...childTags];
        }
      }
    });

    //返回数据
    return tags;
  }
}
