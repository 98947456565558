
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import ThemePicker from "@/comp/ThemePicker/index.vue";
import { SettingsModule } from "@/store/modules/settings";

//组件
@Component({
  name: "Settings",
  components: {
    ThemePicker,
  },
})

//界面函数
export default class extends Vue {
  //获取固定Header
  get fixedHeader() {
    return SettingsModule.fixedHeader;
  }

  //获取显示标签
  get showTagsView() {
    return SettingsModule.showTagsView;
  }

  //获取显示侧边栏Logo
  get showSidebarLogo() {
    return SettingsModule.showSidebarLogo;
  }

  //获取侧边栏文本主题颜色
  get sidebarTextTheme() {
    return SettingsModule.sidebarTextTheme;
  }

  //设置显示标签
  set showTagsView(value) {
    SettingsModule.changeSetting({ key: "showTagsView", value });
  }

  //设置固定Header
  set fixedHeader(value) {
    SettingsModule.changeSetting({ key: "fixedHeader", value });
  }

  //设置显示侧边栏Logo
  set showSidebarLogo(value) {
    SettingsModule.changeSetting({ key: "showSidebarLogo", value });
  }

  //设置获取侧边栏文本主题颜色
  set sidebarTextTheme(value) {
    SettingsModule.changeSetting({ key: "sidebarTextTheme", value });
  }

  //切换主题
  private themeChange(value: string) {
    SettingsModule.changeSetting({ key: "theme", value });
  }
}
