export default {
  //实例
  utils: {
    usernameErrorTips: "请输入用户名",
    passwordErrorTips1: "密码不能少于6位",
    passwordErrorTips2: "密码必须包含数字、字母、特殊字符其中2种",
  },

  //登录
  login: {
    logIn: "登录",
    username: "账号",
    password: "密码",
    title: "后台管理系统",
    loginFailure: "账号或密码错误",
  },

  //导航
  navbar: {
    theme: "主题色",
    logOut: "退出登录",
    profile: "个人中心",
    dashboard: "返回首页",
  },

  //个人中心
  profile: {
    save: "保存",
    cancel: "取消",
    oldPassword: "旧密码",
    newPassword: "新密码",
    changePassword: "修改密码",
    confirmPassword: "确认密码",
    personalInformation: "个人信息",
    pleaseInputOldPassword: "请输入旧密码",
    pleaseInputNewPassword: "请输入新密码",
    pleaseConfirmNewPassword: "请确认新密码",
    changeSuccess: "密码修改成功，请重新登录",
    newPasswordError: "两次输入的密码不一致",
    changeFailure: "修改密码失败",
  },

  //标签
  tagsView: {
    close: "关闭",
    refresh: "刷新",
    closeAll: "关闭所有",
    closeOthers: "关闭其它",
  },

  //设置
  settings: {
    theme: "主题色",
    title: "系统布局配置",
    fixedHeader: "固定Header",
    showTagsView: "显示Tags-View",
    showSidebarLogo: "显示侧边栏Logo",
    sidebarTextTheme: "侧边栏文字主题色",
  },

  //错误页面
  errorpage: {
    back: "返回",
    tips: "提示",
    backDashboard: "回到主页",
    notExistTips: "页面不存在",
    backDashboardTips: "或者你可以去",
    permissionTips: "你没有权限去该页面",
    contactAdmin: "如有不满请联系管理员",
    notExistInfo: "请检查您输入的网址是否正确，或点击下面的按钮返回首页。",
  },

  //权限
  permission: {
    delete: "删除",
    cancel: "取消",
    confirm: "确定",
    roles: "你的权限",
    createRole: "新增角色",
    switchRoles: "切换权限",
    editPermission: "编辑权限",
  },

  //组件
  components: {
    changeLanguageTips: "切换语言成功",
    screenfullErrorTips: "浏览器不支持",
  },
};
