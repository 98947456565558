import Vue from "vue";
import VueI18n from "vue-i18n";
import { getLanguage } from "@/utils/cookies";

//本地引入
import enLocale from "./en";
import zhLocale from "./zh";
import idLocale from "./id";
import elementEnLocale from "element-ui/lib/locale/lang/en";
import elementIdLocale from "element-ui/lib/locale/lang/id";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";

//使用国际化
Vue.use(VueI18n);

//消息
const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale,
  },
  id: {
    ...idLocale,
    ...elementIdLocale,
  },
};

//获取语言
export const getLocale = () => {
  //获取本地语言
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    document.documentElement.lang = cookieLanguage;
    return cookieLanguage;
  }

  //语言赋值
  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      document.documentElement.lang = locale;
      return locale;
    }
  }

  //默认语言
  return "zh";
};

//国际化
const i18n = new VueI18n({
  locale: getLocale(),
  messages,
});

//导出
export default i18n;
