//函数库
const GFunc = {
  //获取列表条数上限
  getListRowLimit() {
    return 2999;
  },

  //数据类型
  getType(obj: any): string {
    const toString = Object.prototype.toString;
    return typeMap[toString.call(obj)];
  },

  //深度拷贝数据
  deepClone(data: any): any {
    //定义变量
    let obj: any;

    //数据类型
    const type = this.getType(data);

    //数组
    if (type === "array") {
      obj = [];
    }
    //数据
    else if (type === "object") {
      obj = {};
    }
    //其他
    else {
      return data;
    }

    //数组处理
    if (type === "array") {
      for (let i = 0, len = data.length; i < len; i++) {
        const tmpData = data === data[i] ? data : this.deepClone(data[i]);
        obj.push(tmpData);
      }
    }
    //数据处理
    else if (type === "object") {
      for (const key in data) {
        obj[key] = data === data[key] ? data : this.deepClone(data[key]);
      }
    }

    //返回数据
    return obj;
  },

  //监测table
  checktable(value: any): any {
    //不是数据接口
    if (typeof value !== "object") {
      value = {};
    }

    //null
    if (this.getType(value) === "null") {
      value = {};
    }

    //返回
    return value;
  },

  //是否数组
  checkarray(value: any): any {
    //不是数组
    if (typeof value !== "object") {
      value = [];
    }

    //null
    if (this.getType(value) === "null") {
      value = [];
    }

    //返回数据
    return value;
  },

  //随机生成字符串
  getRandStr(len: any): string {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const maxPos = chars.length;
    let str = "";
    len = len || 5;
    for (let i = 0; i < len; i++) {
      str += chars[Math.floor(Math.random() * maxPos)];
    }
    return str;
  },

  //是否整数
  checkint(value: any): number {
    return Math.round(this.checknumber(value));
  },

  //去掉空格
  removeSpace(value: any): any {
    if (typeof value === "number") {
      value = Math.floor(value);
    }
    value = GFunc.checkstring(value).replace(/ /g, "");
    return value;
  },

  //是否bool
  checkbool(value: any): boolean {
    const bool = new Boolean(value);
    return bool.valueOf();
  },

  //是否文本
  checkstring(value: any): string {
    //获取类型
    const sType: string = this.getType(value);

    //不是文本和数字
    if (sType !== "string" && sType !== "number") {
      return "";
    }

    //文本
    if (value.toString) return value.toString();

    //返回空
    return "";
  },

  //验证账号是否合法
  validUsername(str: any): boolean {
    str = this.checkstring(str).trim();
    const len = str.length;
    if (len >= 4 && len <= 20) {
      return this.checkNumOrLetter(str);
    }
    return false;
  },

  //验证密码是否合法
  validPassword(str: any): boolean {
    str = this.checkstring(str).trim();
    const len = str.length;
    if (len >= 6 && len <= 16) {
      return this.checkNumOrLetter(str, true);
    }
    return false;
  },

  //是否空数据
  isEmptyObject(object: any): boolean {
    if (!object) {
      return true;
    }
    return Object.keys(object).length == 0;
  },

  //验证密码是否一致
  validPasswordConfirm(pwd: any, confirm: any) {
    pwd = this.checkstring(pwd).trim();
    confirm = this.checkstring(confirm).trim();
    return pwd === confirm && this.validPassword(pwd);
  },

  //是否数值
  checknumber(value: any, def: any = 0): number {
    //数据赋值
    const num = Number(value);

    //空数据
    if (isNaN(num)) {
      return def;
    }

    //返回数字
    return num;
  },

  //是否只有数字或英文
  checkNumOrLetter(value: any, spChars?: boolean) {
    let reg = /^[0-9a-zA-Z]{1,}$/;
    if (spChars) {
      reg = /^[0-9a-zA-Z.@$!%*#_~?&^]{1,}$/;
    }
    return reg.test(value);
  },

  //文本长度补全
  appendStrToLen(value: any, len: any, char: any) {
    len = len != undefined ? len : 1;
    char = char || "0";
    const vLen = value.toString().length;
    let res = "";
    if (vLen < len) {
      for (let i = 0; i < len - vLen; i++) {
        res += char;
      }
    }
    return res + value;
  },

  //强制保留x位小数
  toFixedFloatForce(value: any, decimal?: number) {
    decimal = decimal != undefined ? decimal : 2;
    const base = Math.pow(10, decimal);
    return (Math.floor(this.checknumber(value) * base) / base).toFixed(decimal);
  },

  //强制保留x位小数
  toFixedFloat(value: any, decimal?: number): any {
    decimal = decimal != undefined ? decimal : 2;
    const base = Math.pow(10, decimal);
    return this.checknumber((Math.floor(this.checknumber(value) * base) / base).toFixed(decimal));
  },
};

//数据
type typeMapKey = Record<any, any>;
const typeMap: typeMapKey = {
  "[object Null]": "null",
  "[object Date]": "date",
  "[object Array]": "array",
  "[object Object]": "object",
  "[object Number]": "number",
  "[object RegExp]": "regExp",
  "[object String]": "string",
  "[object Boolean]": "boolean",
  "[object Function]": "function",
  "[object Undefined]": "undefined",
};

//导出
export default GFunc;
