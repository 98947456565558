//公共库
import "@/permission";
import "normalize.css";
import "@/utils/error-log";
import "@/icons/components";
import "@/styles/index.scss";
import "@/styles/element-variables.scss";

//本地引入
import Vue from "vue";
import i18n from "@/lang";
import App from "@/App.vue";
import store from "@/store";
import router from "@/router";
import SvgIcon from "vue-svgicon";
import ElementUI from "element-ui";

//全局设置
Vue.config.productionTip = false;
(ElementUI.Dialog as any).props.closeOnClickModal.default = false;
Vue.use(SvgIcon, { tagName: "svg-icon", defaultWidth: "1em", defaultHeight: "1em" });
Vue.use(ElementUI, { i18n: (key: string, value: string) => i18n.t(key, value) });

//初始化
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
