//本地导入
import request from "../request";

//首页数据
export const homePage = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/home/page",
  });

//账号登录
export const authLogin = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/auth/login",
  });

//获取用户信息
export const getUserInfo = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/userInfo",
  });

//修改密码
export const changePassword = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/editPassWord",
  });

//获取权限列表
export const getPermissionMenu = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/manage/getMenu",
  });

//保存数据
export function saveAs(blob: any, filename: any) {
  const nav = window.navigator as any;
  const navi = navigator as any;
  if (nav.msSaveOrOpenBlob) {
    navi.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    const body = document.querySelector("body");

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.style.display = "none";
    body!.appendChild(link);
    link.click();
    body!.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }
}
