
//本地引入
import settings from "@/settings";

//公共库
import { Component, Prop, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "SidebarLogo",
})

//组件函数
export default class extends Vue {
  @Prop({ required: true }) private collapse!: boolean;

  //标题
  private title = settings.title;
}
