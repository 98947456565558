//本地引入
import store from "@/store";

//公共库
import { Route } from "vue-router";
import { VuexModule, Module, Mutation, Action, getModule } from "vuex-module-decorators";

//标签界面状态结果
export interface ITagsViewState {
  visitedViews: ITagView[];
  cachedViews: (string | undefined)[];
}

//标签界面结构
export interface ITagView extends Partial<Route> {
  title?: string;
}

/**
 * 标签界面模块
 */
@Module({ dynamic: true, store, name: "tagsView" })
class TagsView extends VuexModule implements ITagsViewState {
  //定义变量
  public visitedViews: ITagView[] = [];
  public cachedViews: (string | undefined)[] = [];

  //------------------ 本地函数 ------------------
  //删除所有缓存界面
  @Mutation
  private DEL_ALL_CACHED_VIEWS() {
    this.cachedViews = [];
  }

  //删除所有访问界面
  @Mutation
  private DEL_ALL_VISITED_VIEWS() {
    const affixTags = this.visitedViews.filter((tag) => tag.meta.affix);
    this.visitedViews = affixTags;
  }

  //添加缓存界面
  @Mutation
  private ADD_CACHED_VIEW(view: ITagView) {
    if (view.name === null) return;
    if (this.cachedViews.includes(view.name)) return;
    if (!view.meta.noCache) {
      this.cachedViews.push(view.name);
    }
  }

  //删除缓存界面
  @Mutation
  private DEL_CACHED_VIEW(view: ITagView) {
    if (view.name === null) return;
    const index = this.cachedViews.indexOf(view.name);
    index > -1 && this.cachedViews.splice(index, 1);
  }

  //添加访问界面
  @Mutation
  private ADD_VISITED_VIEW(view: ITagView) {
    if (this.visitedViews.some((v) => v.path === view.path)) return;
    this.visitedViews.push(
      Object.assign({}, view, {
        title: view.meta.title || "no-name",
      })
    );
  }

  //删除访问界面
  @Mutation
  private DEL_VISITED_VIEW(view: ITagView) {
    for (const [i, v] of this.visitedViews.entries()) {
      if (v.path === view.path) {
        this.visitedViews.splice(i, 1);
        break;
      }
    }
  }

  //更新访问界面
  @Mutation
  private UPDATE_VISITED_VIEW(view: ITagView) {
    for (let v of this.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view);
        break;
      }
    }
  }

  //删除其他缓存界面
  @Mutation
  private DEL_OTHERS_CACHED_VIEWS(view: ITagView) {
    if (view.name === null) return;
    const index = this.cachedViews.indexOf(view.name);
    if (index > -1) {
      this.cachedViews = this.cachedViews.slice(index, index + 1);
    } else {
      this.cachedViews = [];
    }
  }

  //删除其他访问界面
  @Mutation
  private DEL_OTHERS_VISITED_VIEWS(view: ITagView) {
    this.visitedViews = this.visitedViews.filter((v) => {
      return v.meta.affix || v.path === view.path;
    });
  }

  //------------------ 外部调用 ------------------
  //删除所有界面
  @Action
  public delAllViews() {
    this.DEL_ALL_VISITED_VIEWS();
    this.DEL_ALL_CACHED_VIEWS();
  }

  //删除所有缓存界面
  @Action
  public delAllCachedViews() {
    this.DEL_ALL_CACHED_VIEWS();
  }

  //添加界面
  @Action
  public addView(view: ITagView) {
    this.ADD_VISITED_VIEW(view);
    this.ADD_CACHED_VIEW(view);
  }

  //删除界面
  @Action
  public delView(view: ITagView) {
    this.DEL_VISITED_VIEW(view);
    this.DEL_CACHED_VIEW(view);
  }

  //删除缓存界面
  @Action
  public delCachedView(view: ITagView) {
    this.DEL_CACHED_VIEW(view);
  }

  //删除其他界面
  @Action
  public delOthersViews(view: ITagView) {
    this.DEL_OTHERS_VISITED_VIEWS(view);
    this.DEL_OTHERS_CACHED_VIEWS(view);
  }

  //添加访问界面
  @Action
  public addVisitedView(view: ITagView) {
    this.ADD_VISITED_VIEW(view);
  }

  //更新访问界面
  @Action
  public updateVisitedView(view: ITagView) {
    this.UPDATE_VISITED_VIEW(view);
  }
}

//导出
export const TagsViewModule = getModule(TagsView);
