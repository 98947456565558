
//本地引入
import { isExternal } from "@/utils/validate";
import SidebarItemLink from "./SidebarItemLink.vue";

//公共库
import path from "path";
import { RouteConfig } from "vue-router";
import { Component, Prop, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "SidebarItem",
  components: {
    SidebarItemLink,
  },
})

//组件函数
export default class extends Vue {
  @Prop({ default: "" }) private basePath!: string;
  @Prop({ required: true }) private item!: RouteConfig;
  @Prop({ default: false }) private isCollapse!: boolean;
  @Prop({ default: true }) private isFirstLevel!: boolean;

  //是否只拥有唯一有效二级菜单
  get theOnlyOneChild(): any {
    if (this.showingChildNumber > 1) {
      return null;
    }
    if (this.item.children) {
      for (const child of this.item.children) {
        if (!child.meta || !child.meta.hidden) {
          return child;
        }
      }
    }

    return { ...this.item, path: "" };
  }

  //子菜单数量
  get showingChildNumber(): any {
    if (this.item.children) {
      const showingChildren = this.item.children.filter((item) => {
        if (item.meta && item.meta.hidden) {
          return false;
        } else {
          return true;
        }
      });
      return showingChildren.length;
    }
    return 0;
  }

  //是否强制显示一级菜单
  get alwaysShowRootMenu(): boolean {
    if (this.item.meta && this.item.meta.alwaysShow) {
      return true;
    }
    return false;
  }

  //拼接完整路径
  private resolvePath(routePath: string): string {
    if (isExternal(routePath)) {
      return routePath;
    }
    if (isExternal(this.basePath)) {
      return this.basePath;
    }
    return path.resolve(this.basePath, routePath);
  }
}
