//本地引入
import GFunc from "./gFunc";
import { parseTime } from ".";

/**
 * 时间处理
 */
const DTCls = {
  //获取当前日
  getCurDate(): any {
    return new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate();
  },

  //获取当前年份
  getCurYear(): number {
    return new Date().getFullYear();
  },

  //获取当前月份 1-12
  getCurMonth(): number {
    return new Date().getMonth() + 1;
  },

  //昨天
  getYesterday(): string {
    //初始化数据
    var today = new Date();
    var yesterday = new Date(today);

    //昨天赋值
    yesterday.setDate(today.getDate() - 1);

    //年月日赋值
    var year = yesterday.getFullYear();
    var day = String(yesterday.getDate()).padStart(2, "0");
    var month = String(yesterday.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1
    var formattedDate = year + "-" + month + "-" + day;

    //返回数据
    return formattedDate;
  },

  //获取当前星期数
  getCurWeekDay(): number {
    //获取数据
    let day = new Date().getDay();
    day = day === 0 ? 7 : day;

    //返回数据
    return day;
  },

  //获取当前时间戳
  getCurDateTime(): number {
    return new Date().getTime();
  },

  //当天年月日
  getCurDateTimeYMD(): any {
    return parseTime(this.getCurDateTime(), "{y}-{m}-{d}");
  },

  //获取当天结束时间戳
  getTodayEndTime(): number {
    //数据赋值
    const date = new Date();
    const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);

    //返回数据
    return endDate.getTime();
  },

  //最近1天
  getCurDataTimeYMD1(): any {
    return parseTime(this.getCurDateTime() - 1 * 24 * 3600 * 1000, "{y}-{m}-{d}");
  },

  //最近7天
  getCurDataTimeYMD7(): any {
    return parseTime(this.getCurDateTime() - 7 * 24 * 3600 * 1000, "{y}-{m}-{d}");
  },

  //最近30天
  getCurDataTimeYMD30(): any {
    return parseTime(this.getCurDateTime() - 30 * 24 * 3600 * 1000, "{y}-{m}-{d}");
  },

  //最近90天
  getCurDataTimeYMD90(): any {
    return parseTime(this.getCurDateTime() - 90 * 24 * 3600 * 1000, "{y}-{m}-{d}");
  },

  //获取当天开始时间戳
  getTodayStartTime(): number {
    //数据赋值
    const date = new Date();
    const firstDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

    //返回数据
    return firstDate.getTime();
  },

  //本周结束时间戳
  getCurWeekEndTime(): number {
    return this.getTodayEndTime() + (7 - this.getCurWeekDay()) * 24 * 3600 * 1000;
  },

  //上周结束时间戳
  getLastWeekEdnTime(): number {
    return this.getCurWeekStartTime() - 1000;
  },

  //本月结束时间戳
  getCurMonthEndTime(): number {
    //数据赋值
    const date = new Date();
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);

    //返回数据
    return endDate.getTime();
  },

  //获取昨天结束时间戳
  getYesterdayEndTime(): number {
    return this.getTodayStartTime() - 1000;
  },

  //上月结束时间戳
  getLastMonthEndTime(): number {
    //数据赋值
    const date = new Date();
    const endDate = new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59, 999);

    //返回数据
    return endDate.getTime();
  },

  //本周开始时间戳
  getCurWeekStartTime(): number {
    return this.getTodayStartTime() - (this.getCurWeekDay() - 1) * 24 * 3600 * 1000;
  },

  //上周开始时间戳
  getLastWeekStartTime(): number {
    return this.getCurWeekStartTime() - 7 * 24 * 3600 * 1000;
  },

  //本月开始时间戳
  getCurMonthStartTime(): number {
    //数据赋值
    const date = new Date();
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);

    //返回数据
    return firstDate.getTime();
  },

  //获取昨天开始时间戳
  getYesterdayStartTime(): number {
    return this.getTodayStartTime() - 24 * 3600 * 1000;
  },

  //上月开始时间戳
  getLastMonthStartTime(): number {
    //数据赋值
    const date = new Date();
    const firstDate = new Date(date.getFullYear(), date.getMonth() - 1, 1, 0, 0, 0, 0);

    //返回数据
    return firstDate.getTime();
  },

  //判断日期是否非法
  isValidDate(date: any): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  },

  //指定日期当天结束时间字符串
  getEndTimeFormat(time: any): string {
    const t = this.getEndTime(time, true);
    if (!t) {
      return "";
    }
    return this.getTimeStringWithSec("-", t);
  },

  //指定日期当天开始时间字符串
  getStartTimeFormat(time: any): string {
    const t = this.getStartTime(time, true);
    if (!t) {
      return "";
    }
    return this.getTimeStringWithSec("-", t);
  },

  //获取当前时分秒
  getTimeStringWithHMS(time?: any): string {
    //数据初始化
    const newTime: any = time || new Date();

    //时间赋值
    const hours = newTime.getHours() < 10 ? "0" + newTime.getHours() : newTime.getHours();
    const minutes = newTime.getMinutes() < 10 ? "0" + newTime.getMinutes() : newTime.getMinutes();
    const seconds = newTime.getSeconds() < 10 ? "0" + newTime.getSeconds() : newTime.getSeconds();
    const timeStr = hours + ":" + minutes + ":" + seconds;

    //返回数据
    return timeStr;
  },

  //将字符串格式化为日期格式
  formatTimeString(str: any, sp = "-"): string {
    //数据赋值
    str = GFunc.checkstring(str).replace(/ /g, "");
    let newstr = "";
    const dtstr = str.match(/(\d{4})(\d{2})(\d{2})/);
    if (dtstr && dtstr.length === 4) {
      newstr = `${dtstr[1]}${sp}${dtstr[2]}${sp}${dtstr[3]}`;
    }

    //返回数据
    return newstr;
  },

  //获取当前年月日
  getDateTimeYMD(time?: any, sp = "-"): string {
    //定义变量
    var nowDate: Date;

    //数据初始化
    if (!time) {
      nowDate = new Date();
    } else {
      nowDate = new Date(time);
    }

    //年月日赋值
    const year = nowDate.getFullYear();
    const day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
    const month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;

    //返回数据
    return `${year}${sp}${month}${sp}${day}`;
  },

  //获取指定日期当天结束时间戳
  getEndTime(time: any, isMini?: boolean): number {
    //异常处理
    if (!time) return 0;

    //数据赋值
    const date = new Date(time);
    const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
    const dev = isMini ? 1 : 1000;

    //返回数据
    return Math.floor(endDate.getTime() / dev);
  },

  //获取指定日期当天开始时间戳
  getStartTime(time: any, isMini?: boolean): number {
    //异常处理
    if (!time) return 0;

    //数据赋值
    const date = new Date(time);
    const firstDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    const dev = isMini ? 1 : 1000;

    //返回
    return Math.ceil(firstDate.getTime() / dev);
  },
  //获取当前年月日毫秒值
  getTimeStringWithSec(format?: any, time?: any): string {
    //数据赋值
    format = format || "-";
    let newTime: any = 0;
    if (!time) {
      newTime = new Date();
    } else {
      newTime = new Date(time);
    }

    //日期时间赋值
    const year = newTime.getFullYear();
    const day = newTime.getDate() < 10 ? "0" + newTime.getDate() : newTime.getDate();
    const hours = newTime.getHours() < 10 ? "0" + newTime.getHours() : newTime.getHours();
    const minutes = newTime.getMinutes() < 10 ? "0" + newTime.getMinutes() : newTime.getMinutes();
    const seconds = newTime.getSeconds() < 10 ? "0" + newTime.getSeconds() : newTime.getSeconds();
    const month = newTime.getMonth() + 1 < 10 ? "0" + (newTime.getMonth() + 1) : newTime.getMonth() + 1;
    const timeStr = year + "" + format + month + "" + format + day + " " + hours + ":" + minutes + ":" + seconds;

    //返回数据
    return timeStr;
  },
};

export default DTCls;
