//本地引入
import { AppModule, DeviceType } from "@/store/modules/app";

//公共库
import { Component, Vue, Watch } from "vue-property-decorator";

//宽度
const WIDTH = 992;

//组件
@Component({
  name: "ResizeMixin",
})

//组件函数
export default class extends Vue {
  //初始化
  mounted(): void {
    //获取设备
    const isMobile = this.isMobile();

    //手机初始化
    if (isMobile) {
      //关闭侧边栏
      AppModule.closeSideBar(true);

      //切换设备
      AppModule.toggleDevice(DeviceType.Mobile);
    }
  }

  //创建前
  beforeMount(): void {
    //注册事件
    window.addEventListener("resize", this.resizeHandler);
  }

  //销毁前
  beforeDestroy(): void {
    //注销事件
    window.removeEventListener("resize", this.resizeHandler);
  }

  //------------------------------- 数据 -------------------------------
  //侧边栏
  get sidebar(): any {
    return AppModule.sidebar;
  }

  //运行设备
  get device(): DeviceType {
    return AppModule.device;
  }

  //------------------------------- 函数 -------------------------------
  //路由切换
  @Watch("$route")
  private onRouteChange() {
    //移动设备打开侧边栏
    if (this.device === DeviceType.Mobile && this.sidebar.opened) {
      AppModule.closeSideBar(false);
    }
  }

  //是否移动设备
  private isMobile(): boolean {
    //获取数据
    const rect = document.body.getBoundingClientRect();

    //返回结果
    return rect.width - 1 < WIDTH;
  }

  //处理尺寸变化
  private resizeHandler(): void {
    //未最小化
    if (!document.hidden) {
      //设备赋值
      const isMobile = this.isMobile();

      //切换设备
      AppModule.toggleDevice(isMobile ? DeviceType.Mobile : DeviceType.Desktop);

      //关闭侧边栏
      if (isMobile) AppModule.closeSideBar(true);
    }
  }
}
